import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import moment from 'moment';
import 'moment-timezone';

import DataTable from "react-data-table-component";

import FilterComponent from '../../base/FilterComponent';
import ExpandedComponent from '../../base/ExpandedComponent';

import AuthenticationService from '../../../services/security/AuthenticationService';

import { formatUtilities } from '../../../utilities/format_utilities';

import SockJsClient from 'react-stomp';

const FINAPI_URL = process.env.REACT_APP_FINAPI_URL;
const WEBSOCKET_URL = `${FINAPI_URL}/ws-stomp`;

function ImportStocksSMM() {

    const [client, setClient] = useState(undefined);
    const [logData, setLogData] = useState([]);
    const [importedCompanies, setImportedCompanies] = useState([]);
    const [importedStocks, setImportedStocks] = useState([]);
    const [submittedStartRow, setSubmittedStartRow] = useState(undefined);
    const [submittedEndRow, setSubmittedEndRow] = useState(undefined);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        mic: Yup.string()
            .required('MIC is required'),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    const onConnected = () => {
        console.log(`WebSocket connected to ${WEBSOCKET_URL}`);
    };
  
    const onConnectFailed = (error) => {
        console.log(`WebSocket failed to connect to ${WEBSOCKET_URL}, error =`, error);
    };
  
    const onDisconnected = () => {
        console.log(`WebSocket disconnected from ${WEBSOCKET_URL}`);
    };

    const onMessageReceived = (data) => {
        //console.log('Message received on WebSocket, data =', data);
        
        if ('message' in data) {
            setLogData([...logData, data]);
        }
        else if ('companyGuid' in data) {
            setImportedCompanies([...importedCompanies, data]);
        }
        else if ('stockGuid' in data) {
            setImportedStocks([...importedStocks, data]);
        }
    };

    function onSubmit(data) {

        //console.log('data =', data);
        //console.log(`onSubmit, mic = ${data.mic}, startRow = ${data.startRow}, endRow = ${data.endRow}`);

        setLogData([]);
        setImportedCompanies([]);
        setImportedStocks([]);
        setSubmittedStartRow(data.startRow);
        setSubmittedEndRow(data.endRow);

        client.sendMessage('/app/import-stocks-smm', JSON.stringify(data));
    }

    const companyColumns = [
        {
            name: "CIK",
            selector: "cik",
            sortable: true,
            maxWidth: '100px',
        },
        {
            name: "LEI",
            selector: "lei",
            sortable: true,
        },
        {
            name: "Name",
            selector: "name",
            sortable: true,
        },
        {
            name: "City",
            selector: "city",
            sortable: false,
            maxWidth: '150px',
        },
        {
            name: "Country",
            selector: "country",
            sortable: false,
        },
        {
            name: "Country Code",
            selector: "countryCode",
            sortable: false,
            maxWidth: '50px',
        },
        {
            name: "GICS Sector Name",
            selector: "gicsSectorName",
            sortable: true,
            right: true,
        },
        {
            name: "GICS Industry Name",
            selector: "gicsIndustryName",
            sortable: false,
            right: true,
        },
    ];

    const [companiesFilterText, setCompaniesFilterText] = useState('');
    const [companiesFilterToggle, setCompaniesFilterToggle] = useState(false);
  
    const companiesFilteredData = importedCompanies.filter(company => company.name && company.name.toLowerCase().includes(companiesFilterText.toLowerCase()));
  
    const handleCompaniesFilterTextChange = event => {
        setCompaniesFilterText(event.target.value);
    };
  
    const companiesSubHeaderComponentMemo = React.useMemo(() => {
  
      const handleFilter = () => {
        setCompaniesFilterToggle(!companiesFilterToggle);
      };
  
      const handleClear = () => {
        if (companiesFilterText) {
            setCompaniesFilterText('');
        }
      };
  
      return <FilterComponent filterText={companiesFilterText} onTextChange={handleCompaniesFilterTextChange} onFilter={handleFilter} onClear={handleClear} />;
    }, [companiesFilterText, companiesFilterToggle]);

    const stockColumns = [
        {
            name: 'Ticker Symbol',
            selector: 'tickerSymbol',
            sortable: true,
            maxWidth: '100px',
        },
        {
            name: 'Name',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'FIGI',
            selector: 'figi',
            sortable: false,
        },
        {
            name: 'ISIN',
            selector: 'isin',
            sortable: false,
        },
        {
            name: 'MIC',
            selector: 'mic',
            sortable: false,
            maxWidth: '50px',
        },
        {
            name: 'Market Cap',
            selector: (row, index) => formatUtilities.formatLongInteger(row.marketCap),
            sortable: true,
            right: true,
        },
        {
            name: 'Currency',
            selector: 'currencyCode',
            sortable: false,
            right: true,
            maxWidth: '50px',
        },
    ];

    const [stocksFilterText, setStocksFilterText] = useState('');
    const [stocksFilterToggle, setStocksFilterToggle] = useState(false);
  
    const stocksFilteredData = importedStocks.filter(stock => stock.name && stock.name.toLowerCase().includes(stocksFilterText.toLowerCase()));
  
    const handleStocksFilterTextChange = event => {
        setStocksFilterText(event.target.value);
    };
  
    const stocksSubHeaderComponentMemo = React.useMemo(() => {
  
      const handleFilter = () => {
        setStocksFilterToggle(!stocksFilterToggle);
      };
  
      const handleClear = () => {
        if (stocksFilterText) {
            setStocksFilterText('');
        }
      };
  
      return <FilterComponent filterText={stocksFilterText} onTextChange={handleStocksFilterTextChange} onFilter={handleFilter} onClear={handleClear} />;
    }, [stocksFilterText, stocksFilterToggle]);

    const logDataColumns = [
        {
            name: "Date",
            selector: (row, index) => moment(row.logDate).tz('America/Los_Angeles').format('YYYY-MM-DD hh:mm:ss A'),
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "Level",
            selector: "level",
            sortable: true,
            maxWidth: '50px',
        },
        {
            name: "Category",
            selector: (row, index) => {
                const categoryParts = row.category.split('.');
                return categoryParts[categoryParts.length - 1];
            },
            sortable: true,
            maxWidth: '200px',
        },
        {
            name: "Message",
            selector: "message",
            sortable: false,
            wrap: true,
        },
    ];

    const [logDataFilterText, setLogDataFilterText] = useState('');
    const [logDataFilterToggle, setLogDataFilterToggle] = useState(false);
  
    const logDataFilteredData = logData.filter(logData => logData.message && logData.message.toLowerCase().includes(logDataFilterText.toLowerCase()));
  
    const handleLogDataFilterTextChange = event => {
        setLogDataFilterText(event.target.value);
    };
  
    const logDataSubHeaderComponentMemo = React.useMemo(() => {
  
      const handleFilter = () => {
        setLogDataFilterToggle(!logDataFilterToggle);
      };
  
      const handleClear = () => {
        if (logDataFilterText) {
            setLogDataFilterText('');
        }
      };
  
      return <FilterComponent filterText={logDataFilterText} onTextChange={handleLogDataFilterTextChange} onFilter={handleFilter} onClear={handleClear} />;
    }, [logDataFilterText, logDataFilterToggle]);


    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>Import Stocks from Stock Market MBA</h1>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>MIC</label>
                    <input type="text" {...register('mic')} className={`form-control ${errors.mic ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.mic?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Start Row</label>
                    <input type="text" {...register('startRow')} className={`form-control ${errors.startRow ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.startRow?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>End Row</label>
                    <input type="text" {...register('endRow')} className={`form-control ${errors.endRow ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.endRow?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Proceed
                </button>
                <Link to='/' className="btn btn-link">Cancel</Link>
            </div>
            <SockJsClient
                url={WEBSOCKET_URL}
                headers={AuthenticationService.getStompConnectionHeaders()}
                topics={['/topic/data_import_company', '/topic/data_import_stock', '/topic/data_import_stock_log']}
                onConnect={onConnected}
                onConnectFailure={onConnectFailed}
                onDisconnect={onDisconnected}
                onMessage={msg => onMessageReceived(msg)}
                ref={clientRef => setClient(clientRef)}
                debug={false}
            />
            {importedCompanies && importedCompanies.length > 0 &&
                <DataTable
                    title={`Imported [${importedCompanies.length}] Companies`}
                    columns={companyColumns}
                    data={companiesFilteredData}
                    keyField="companyGuid"
                    striped
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="78vh"
                    pagination
                    paginationTotalRows={companiesFilteredData.length}
                    subHeader
                    subHeaderComponent={companiesSubHeaderComponentMemo}
                    expandableRows
                    expandableRowsComponent={<ExpandedComponent />}
                    />
            }
            {importedStocks && importedStocks.length > 0 &&
                <DataTable
                    title={`Imported [${importedStocks.length}] Stocks (rows ${submittedStartRow} to ${submittedEndRow})`}
                    columns={stockColumns}
                    data={stocksFilteredData}
                    keyField='stockGuid'
                    striped
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight='78vh'
                    pagination
                    paginationTotalRows={stocksFilteredData.length}
                    subHeader
                    subHeaderComponent={stocksSubHeaderComponentMemo}
                    expandableRows
                    expandableRowsComponent={<ExpandedComponent />}
                    />
            }
            {logData && logData.length > 0 &&
                <DataTable
                    title={`Logged [${logData.length}] messages`}
                    columns={logDataColumns}
                    data={logDataFilteredData}
                    striped
                    highlightOnHover
                    fixedHeader
                    fixedHeaderScrollHeight="78vh"
                    pagination
                    paginationTotalRows={logDataFilteredData.length}
                    subHeader
                    subHeaderComponent={logDataSubHeaderComponentMemo}
                    expandableRows
                    expandableRowsComponent={<ExpandedComponent />}
                    />
            }
        </form>
    );
}

export default ImportStocksSMM;