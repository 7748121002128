import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ListCompanies from './ListCompanies';
import AddEditCompany from './AddEditCompany';

function AllCompanies({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={ListCompanies} />
            <Route path={`${path}/add`} component={AddEditCompany} />
            <Route path={`${path}/edit/:id`} component={AddEditCompany} />
        </Switch>
    );
}

export default AllCompanies;