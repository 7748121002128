import React, { useState, useEffect } from "react";

import memoize from 'memoize-one';
import DataTable from "react-data-table-component";

import { MdAdd, MdDelete } from 'react-icons/md';

import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';

import FilterComponent from '../../base/FilterComponent';
import ExpandedComponent from '../../base/ExpandedComponent';
import EntityContextMenu from '../../base/EntityContextMenu';

import { companyService } from '../../../services/common/company_service';
import { alertService } from '../../../services/common/alert_service';

import AuthenticationService from '../../../services/security/AuthenticationService';

const selectProps = { indeterminate: isIndeterminate => isIndeterminate };

const actions = memoize(addHandler => (
  <IconButton color="primary" onClick={addHandler}>
    <MdAdd />
  </IconButton>
));

const contextActions = memoize(deleteAllHandler => (
  <IconButton color="secondary" onClick={deleteAllHandler}>
    <MdDelete />
  </IconButton>
));

const columns = memoize((updateHandler, deleteHandler) => [
  {
    name: "CIK",
    selector: "cik",
    sortable: true,
  },
  {
    name: "LEI",
    selector: "lei",
    sortable: true,
  },
  {
    name: "Name",
    selector: "name",
    sortable: true,
  },
  {
    name: "City",
    selector: "city",
    sortable: false,
  },
  {
    name: "Country",
    selector: "country",
    sortable: false,
  },
  {
    name: "Country Code",
    selector: "countryCode",
    sortable: false,
  },
  {
    name: "GICS Sector Name",
    selector: "gicsSectorName",
    sortable: true,
    right: true,
  },
  {
    name: "GICS Industry Name",
    selector: "gicsIndustryName",
    sortable: false,
    right: true,
  },
  (AuthenticationService.hasRole("ADMIN") ?
  {
    cell: row => <EntityContextMenu row={row} onUpdateRow={updateHandler} onDeleteRow={deleteHandler} />,
    allowOverflow: true,
    button: true,
    width: '56px', // custom width for icon button
  } :
  {
    cell: row => "",
    width: '1px',
    omit: true
  }
  )
]);

function ListCompanies(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  const [filterText, setFilterText] = useState('');
  const [filterToggle, setFilterToggle] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleSelectedRowsChange = state => {
    setSelectedRows( state.selectedRows );
  };

  const handleRowClicked = row => {
    //console.log(`${row.name} was clicked!`);
  }

  const addOne = () => {
    //console.log("add clicked");
    props.history.push("/companies/add");
  }

  const updateOne = row => {
    //console.log(`update clicked on name: ${row.name}`);
    props.history.push(`/companies/edit/${row.companyGuid}`);
  }

  const deleteOne = row => {
    
    if (window.confirm(`Are you sure you want to delete:\r ${row.name}?`)) {

      companyService.delete(row.companyGuid)
        .then(() => {
          alertService.success(`Company ${row.name} deleted.`, { autoClose: true });
          setToggleCleared( !toggleCleared );
        })
        .catch(
            (message, options) => {
                alertService.error(message, {...options, autoClose: false})
            }
        );

    }
  }

  const deleteAll = () => {
    console.log('deleteAll: selectedRows:', selectedRows);

    const selectedCompanyNames = selectedRows.map(r => r.name);
    
    if (window.confirm(`Are you sure you want to delete:\r ${selectedCompanyNames}?`)) {

    companyService.deleteAll(selectedRows)
      .then((successMessage) => {
          alertService.success(successMessage, { keepAfterRouteChange: true });
          setToggleCleared( !toggleCleared );
      })
      .catch(
          (message, options) => {
              alertService.error(message, {...options, autoClose: false})
          }
      );
    }
  }

  const fetchCompanies = async () => {
    setLoading(true);

    //console.log('page', page, 'perPage', perPage);
    
    const zeroIndexedPage = page - 1;
    const sortParam = sortColumn === "" ? "" : `&sort=${sortColumn},${sortDirection}`;
    const searchParam = filterText === "" ? "" : `&search=${filterText}`;

    const response = await companyService.getAll(
      `?page=${zeroIndexedPage}&size=${perPage}${sortParam}${searchParam}`
    )
    .catch(
      function (errorMsg) {
        console.log(errorMsg);
        alertService.error(errorMsg, {autoClose: false});
      }
    );

    //console.log('response', response);

    if (response) {
      // console.log('data', response.data.content);
      setData(response.data.content);
      setTotalRows(response.data.totalElements);
      setLoading(false);
    }
  };

  const handlePageChange = currentPage => {
    setPage(currentPage);
  };

  const handlePerRowsChange = async (newPerPage, currentPage) => {
    setPerPage(newPerPage);
    setPage(currentPage);
  };

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
  };

  const handleFilterTextChange = event => {
    setFilterText(event.target.value);
  };

  const subHeaderComponentMemo = React.useMemo(() => {

    const handleFilter = () => {
      setFilterToggle(!filterToggle);
      setPage(1);
    };

    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return <FilterComponent filterText={filterText} onTextChange={handleFilterTextChange} onFilter={handleFilter} onClear={handleClear} />;
  }, [filterText, filterToggle, resetPaginationToggle]);

  useEffect(() => {
    fetchCompanies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, sortColumn, sortDirection, filterToggle, resetPaginationToggle, toggleCleared]);

  return (
    <div className="Companies">
      {AuthenticationService.hasRole("ADMIN") ?
        <DataTable
          title="Companies"
          columns={columns(updateOne, deleteOne)}
          data={data}
          progressPending={loading}
          keyField="companyGuid"
          striped
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="78vh"
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationResetDefaultPage={resetPaginationToggle}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandableRowsComponent={<ExpandedComponent />}
          actions={actions(addOne)}
          contextActions={contextActions(deleteAll)}
          selectableRows
          selectableRowsComponent={Checkbox}
          selectableRowsComponentProps={selectProps}
          onSelectedRowsChange={handleSelectedRowsChange}
          clearSelectedRows={toggleCleared}
          onRowClicked={handleRowClicked}
        /> :
        <DataTable
          title="Companies"
          columns={columns(updateOne, deleteOne)}
          data={data}
          progressPending={loading}
          keyField="companyGuid"
          striped
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight="78vh"
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationResetDefaultPage={resetPaginationToggle}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          sortServer
          onSort={handleSort}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandableRowsComponent={<ExpandedComponent />}
        />
      }
    </div>
  );
}

export default ListCompanies;