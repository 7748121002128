
import axios from "axios";

const FINAPI_URL = process.env.REACT_APP_FINAPI_URL;
const baseUrl = `${FINAPI_URL}/Stock`;

const getAll = async (params) => {

  const response = await axios.get(
      `${baseUrl}${params}`
    )
    .catch( handleRequestError );
  
  return response;
}

const getById = async (id) => {
  //console.log("get stock by id =", id, "baseUrl =", baseUrl)

  const response = await axios.get(
    `${baseUrl}/${id}`
  )
  .catch( handleRequestError );
  
  return response.data;
}

const getByFigi = async (figi) => {
  //console.log("get stock by FIGI =", figi, "baseUrl =", baseUrl)

  const response = await axios.get(
    `${baseUrl}/findByFIGI/${figi}`
  )
  .catch( handleRequestError );
  
  return response.data;
}

const findByFIGIs = async (stocksWithFigi) => {
  //console.log("find stocks by FIGIs, stocksWithFigi =", stocksWithFigi, "baseUrl =", baseUrl)

  const response = await axios.post(
    `${baseUrl}/findByFIGIs`, stocksWithFigi
  )
  .catch( handleRequestError );

  return response.data;
}

const create = async (data) => {
  console.log("create stock, data =", data, "baseUrl =", baseUrl)

  const response = await axios.post(
    baseUrl, data
  )
  .catch( handleRequestError );

  return response.data;
}

const update = async (id, data) => {
  console.log("update stock, id =", id, ", data =", data, "baseUrl =", baseUrl)

  const response = await axios.put(
    `${baseUrl}/${id}`, data
  )
  .catch( handleRequestError );

  return response.data;
}

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (id) => {
  console.log("delete stock, id =", id, "baseUrl =", baseUrl)

  const response = await axios.delete(
    `${baseUrl}/${id}`
  )
  .catch( handleRequestError );

  return response;
}

const deleteAll = async (batchData) => {
  console.log("delete stocks, batchData =", batchData, "baseUrl =", baseUrl)

  const response = await axios.post(
    `${baseUrl}/deleteInBatch`, batchData
  )
  .catch( handleRequestError );

  return response.data;
}

const loadSmmStocks = async (data) => {
  //console.log("loading SMM stocks, data =", data, "baseUrl =", baseUrl);

  let limitingParams = '';

  if (data.startRow) {
    limitingParams = `?startRow=${data.startRow}`;
  }

  if (data.endRow) {
    if (limitingParams !== '') {
      limitingParams += '&';
    }
    else {
      limitingParams += '?';
    }

    limitingParams += `endRow=${data.endRow}`;
  }

  //console.log("loading SMM stocks, limitingParams =", limitingParams);

  const response = await axios.get(
    `${baseUrl}/loadSmmStocks/${data.mic}${limitingParams}`
  )
  .catch( handleRequestError );

  return response.data;
}

const handleRequestError = (error) => {

  const errorMsg =
    (error.response && ("Error making server call: server responded with: " +
      `${error.response.data.path} ${error.response.data.status} ${error.response.data.error}`)
    ) ||
    (error.request && `Error making server call: no response was received, request = ${error.request}`) ||
    `Error making server call: problem setting up request, message = ${error.message}`;

  return Promise.reject(errorMsg);
}

export const stockService = {
    getAll,
    getById,
    getByFigi,
    findByFIGIs,
    create,
    update,
    delete: _delete,
    deleteAll,
    loadSmmStocks
};