const selectMarketSessionData = (row) => {
  if (row.preMarketPrice) {
    return {
      period: 'preMarket',
      price: row.preMarketPrice,
      priceChange: row.preMarketPriceChange,
      changePercent: row.preMarketChangePercent
    };
  }
  else if (row.postMarketPrice) {
    return {
      period: 'postMarket',
      price: row.postMarketPrice,
      priceChange: row.postMarketPriceChange,
      changePercent: row.postMarketChangePercent
    };
  }
  else {
    return {
      period: 'regularMarket',
      price: row.price,
      priceChange: row.priceChange,
      changePercent: row.dayChangePercent};
  }
}

const priceChangeStyling = (preMarketBgColor, postMarketBgColor) => {
  return [
    {
      when: row => selectMarketSessionData(row).priceChange < 0,
      style: {
        color: 'red',
      },
    },
    {
      when: row => selectMarketSessionData(row).priceChange > 0,
      style: {
        color: 'green',
      },
    },
    // pre market
    {
      when: row => selectMarketSessionData(row).priceChange < 0 && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'red',
        backgroundColor: preMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).priceChange > 0 && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'green',
        backgroundColor: preMarketBgColor,
      },
    },
    // post market
    {
      when: row => selectMarketSessionData(row).priceChange < 0 && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'red',
        backgroundColor: postMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).priceChange > 0 && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'green',
        backgroundColor: postMarketBgColor,
      },
    },
  ]
}

const changePercentStyling = (percent, preMarketBgColor, postMarketBgColor) => {
  return [
    // regular market
    {
      when: row => selectMarketSessionData(row).changePercent < 0,
      style: {
        color: 'red',
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0,
      style: {
        color: 'green',
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent < 0 && selectMarketSessionData(row).changePercent <= -percent,
      style: {
        color: 'red',
        fontWeight: 'bold',
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0 && selectMarketSessionData(row).changePercent >= percent,
      style: {
        color: 'green',
        fontWeight: 'bold',
      },
    },
    // pre market
    {
      when: row => selectMarketSessionData(row).changePercent < 0 && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'red',
        backgroundColor: preMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0 && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'green',
        backgroundColor: preMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent < 0 && selectMarketSessionData(row).changePercent <= -percent && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'red',
        fontWeight: 'bold',
        backgroundColor: preMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0 && selectMarketSessionData(row).changePercent >= percent && selectMarketSessionData(row).period === 'preMarket',
      style: {
        color: 'green',
        fontWeight: 'bold',
        backgroundColor: preMarketBgColor,
      },
    },
    // post market
    {
      when: row => selectMarketSessionData(row).changePercent < 0 && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'red',
        backgroundColor: postMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0 && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'green',
        backgroundColor: postMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent < 0 && selectMarketSessionData(row).changePercent <= -percent && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'red',
        fontWeight: 'bold',
        backgroundColor: postMarketBgColor,
      },
    },
    {
      when: row => selectMarketSessionData(row).changePercent > 0 && selectMarketSessionData(row).changePercent >= percent && selectMarketSessionData(row).period === 'postMarket',
      style: {
        color: 'green',
        fontWeight: 'bold',
        backgroundColor: postMarketBgColor,
      },
    },
  ]
}

const withinCertainPercentStyling = (field, baseField, percent, bgColor) => {
  return [
    {
      when: row => {
        const percentDiff = (row[field] - row[baseField]) / row[baseField];
        return (percentDiff < 0 && percentDiff > -percent) || (percentDiff >=0 && percentDiff < percent);
      },
      style: {
        backgroundColor: bgColor,
      },
    },
  ]
}

const marketSessionStyling = (preMarketBgColor, postMarketBgColor) => {
  return [
    {
      when: row => {
        return selectMarketSessionData(row).period === 'preMarket';
      },
      style: {
        backgroundColor: preMarketBgColor,
      },
    },
    {
      when: row => {
        return selectMarketSessionData(row).period === 'postMarket';
      },
      style: {
        backgroundColor: postMarketBgColor,
      },
    },
  ]
}

export const tableStyling = {
  selectMarketSessionData,
  priceChangeStyling,
  changePercentStyling,
  withinCertainPercentStyling,
  marketSessionStyling
};