import React from "react";
import { useIntl } from "react-intl";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";

import { Link } from "react-router-dom";

import { AiFillControl, AiOutlineStock, AiOutlineLogout } from "react-icons/ai";
import { FaTachometerAlt, FaDatabase } from "react-icons/fa";
import {
  FiArrowRightCircle,
  FiArrowLeftCircle,
  FiSettings,
} from "react-icons/fi";

import sidebarBg from "../../images/railroad_tracks.jpg";

import AuthenticationService from '../../services/security/AuthenticationService';

const FinanceSidebar = ({
  image,
  collapsed,
  rtl,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  const intl = useIntl();

  const collapseClicked = () => {
    //condition checking to change state from true to false and vice versa
    collapsed ? handleCollapsedChange(false) : handleCollapsedChange(true);
  };

  return (
    <ProSidebar
      image={image ? sidebarBg : false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="xl"
      //breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            textTransform: "uppercase",
            fontWeight: "bold",
            fontSize: 14,
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          {collapsed
            ? intl.formatMessage({ id: "sidebarTitleCompact" })
            : intl.formatMessage({ id: "sidebarTitle" })}
        </div>
        <div className="closemenu" onClick={collapseClicked}>
          {/* changing menu collapse icon on click */}
          {collapsed ? <FiArrowRightCircle /> : <FiArrowLeftCircle />}
        </div>
      </SidebarHeader>

      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem
            icon={<FaTachometerAlt />}
            suffix={
              <span className="badge light-blue">
                {intl.formatMessage({ id: "new" })}
              </span>
            }
          >
            {intl.formatMessage({ id: "dashboard" })}
            <Link to="/dashboard" />
          </MenuItem>
        </Menu>

        {AuthenticationService.hasRole("ADMIN") &&
        <Menu iconShape="circle" popperArrow={true}>
          <SubMenu
            title={intl.formatMessage({ id: "controlPanel" })}
            icon={<AiFillControl />}
            defaultOpen={false}
          >
            <SubMenu
              title={`${intl.formatMessage({ id: "users" })}`}
              defaultOpen={false}
            >
              <MenuItem>
                {intl.formatMessage({ id: "allUsers" })}{" "}
                <Link to="/users" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: "addUser" })}{" "}
                <Link to="/users/add" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title={`${intl.formatMessage({ id: "import" })}`}
              defaultOpen={false}
            >
              <MenuItem>
                Stocks from SMM{" "}
                <Link to="/import/stocks/SMM" />
              </MenuItem>
              <MenuItem>
                Stocks from SMM WS{" "}
                <Link to="/import/stocks/StockMarketMBA" />
              </MenuItem>
              <MenuItem>
                Stocks from Polygon{" "}
                <Link to="/import/stocks/Polygon" />
              </MenuItem>
            </SubMenu>
            <MenuItem>
              {intl.formatMessage({ id: "worldTimes" })}{" "}
              <Link to="/worldTimes" />
            </MenuItem>
          </SubMenu>
        </Menu>
        }

        <Menu iconShape="circle" popperArrow={true}>
          <SubMenu
            title={intl.formatMessage({ id: "data" })}
            icon={<FaDatabase />}
            defaultOpen={true}
          >
            <MenuItem>{intl.formatMessage({ id: "portfolio" })} </MenuItem>
            <SubMenu
              title={`${intl.formatMessage({ id: "stockLists" })}`}
              defaultOpen={true}
            >
              <MenuItem>
                {intl.formatMessage({ id: "allStockLists" })}{" "}
                <Link to="/stockLists" />
              </MenuItem>
              <MenuItem>
                LBC Test List{" "}
                <Link to="/stockLists/watch/LBC Test List/2021-06-09" />
              </MenuItem>
            </SubMenu>
            <SubMenu
              title={`${intl.formatMessage({ id: "securitiesExchange" })}`}
              defaultOpen={true}
            >
              <MenuItem>
                {intl.formatMessage({ id: "exchanges" })}{" "}
                <Link to="/securityExchanges" />
              </MenuItem>
              <MenuItem>
                {intl.formatMessage({ id: "companies" })}{" "}
                <Link to="/companies" />
              </MenuItem>
              <SubMenu
                title={`${intl.formatMessage({ id: "stocks" })}`}
                icon={<AiOutlineStock />}
                defaultOpen={true}
              >
                <MenuItem>
                  {intl.formatMessage({ id: "allStocks" })}{" "}
                  <Link to="/stocks" />
                </MenuItem>
              </SubMenu>
            </SubMenu>
          </SubMenu>
        </Menu>
        <Menu iconShape="circle">
          <MenuItem icon={<AiOutlineLogout />}>
            {intl.formatMessage({ id: "logout" })}
            <Link to="/logout" />
          </MenuItem>
        </Menu>
      </SidebarContent>

      <SidebarFooter style={{ textAlign: "center" }}>
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: "20px 24px",
          }}
        >
          <Link to="/settings">
            <FiSettings /> {intl.formatMessage({ id: "settings" })}
          </Link>
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default FinanceSidebar;
