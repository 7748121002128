import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { securityExchangeService } from '../../../services/common/securityExchange_service';

function AddEditExchange({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;

    //console.log("isAddMode =", isAddMode, "match =", match);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Exchange Name is required'),
        mic: Yup.string()
            .required('MIC is required'),
        operatingMic: Yup.string()
            .required('Operating MIC is required'),
        city: Yup.string()
            .required('City is required'),
        country: Yup.string()
            .required('Country is required'),
        countryCode: Yup.string()
            .required('Country Code is required')
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        return isAddMode
            ? createExchange(data)
            : updateExchange(id, data);
    }

    function createExchange(data) {
        return securityExchangeService.create(data)
            .then((securityExchange) => {
                alertService.success(`Exchange ${securityExchange.name} added`, { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function updateExchange(id, data) {
        return securityExchangeService.update(id, data)
            .then((securityExchange) => {
                alertService.success(`Exchange ${securityExchange.name} updated`, { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(
                (message, options) => {
                    alertService.error(message, {...options, autoClose: false})
                }
            );
    }

    useEffect(() => {
        if (!isAddMode) {
            // get securityExchange and set form fields
            securityExchangeService.getById(id).then(securityExchange => {

                console.log("securityExchange =", securityExchange);

                const fields = ['exchangeGuid','name', 'mic', 'operatingMic',
                    'city', 'country', 'countryCode',
                    'website', 'aliases', 'description',
                    'bloomExchCode', 'bloomCompCode', 'googleExchId',
                    'createdBy','createdDate'];
                fields.forEach(field => setValue(field, securityExchange[field]));
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>{isAddMode ? 'Add Security Exchange' : 'Edit Security Exchange'}</h1>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>Security Exchange Name</label>
                    <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>MIC</label>
                    <input name="mic" type="text" {...register('mic')} className={`form-control ${errors.mic ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.mic?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Operating MIC</label>
                    <input name="operatingMic" type="text" {...register('operatingMic')} className={`form-control ${errors.operatingMic ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.operatingMic?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>City</label>
                    <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.city?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Country</label>
                    <input name="country" type="text" {...register('country')} className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.country?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Country Code</label>
                    <input name="countryCode" type="text" {...register('countryCode')} className={`form-control ${errors.countryCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.countryCode?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-4">
                    <label>Website</label>
                    <input name="website" type="text" {...register('website')} className={`form-control ${errors.website ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.website?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Aliases</label>
                    <input name="aliases" type="text" {...register('aliases')} className={`form-control ${errors.aliases ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.aliases?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>Bloomberg Exchange Code</label>
                    <input name="bloomExchCode" type="text" {...register('bloomExchCode')} className={`form-control ${errors.bloomExchCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.bloomExchCode?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Bloomberg Composite Code</label>
                    <input name="bloomCompCode" type="text" {...register('bloomCompCode')} className={`form-control ${errors.bloomCompCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.bloomCompCode?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Google Exchange Code</label>
                    <input name="googleExchId" type="text" {...register('googleExchId')} className={`form-control ${errors.googleExchId ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.googleExchId?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-7">
                    <label>Description</label>
                    <input name="description" type="text" {...register('description')} className={`form-control ${errors.description ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.description?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
            <input name="exchangeGuid" type="hidden" {...register('exchangeGuid')} />
            <input name="createdBy" type="hidden" {...register('createdBy')} />
            <input name="createdDate" type="hidden" {...register('createdDate')} />
        </form>
    );
}

export default AddEditExchange;