const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const formatCurrency = (value, currencyCode, locale = "en-US") => {
  if (value && currencyCode) {
    if (currencyCode === 'USD') {
      return usdFormatter.format(value);
    }
    else {
      return new Intl.NumberFormat(locale, {style: 'currency', currency: currencyCode}).format(value);
    }
  }
  else {
    return '';
  }
};

const formatPercent = (value, locale = "en-US") => {
  if (value) {
    return new Intl.NumberFormat(locale, {style: 'percent', minimumFractionDigits: 2, maximumFractionDigits: 2}).format(value);
  }
  else {
    return '';
  }
};

const formatLongInteger = (value, locale = "en-US") => {
  if (value) {
    return new Intl.NumberFormat(locale).format(value);
  }
  else {
    return '';
  }
};

export const formatUtilities = {
  formatCurrency,
  formatPercent,
  formatLongInteger
};
