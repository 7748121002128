import React, { useState } from "react";
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { stockService } from '../../../services/common/stock_service';

function ImportStocksSMM() {

    const [importedStocks, setImportedStocks] = useState([]);
    const [submittedStartRow, setSubmittedStartRow] = useState(undefined);
    const [submittedEndRow, setSubmittedEndRow] = useState(undefined);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        mic: Yup.string()
            .required('MIC is required'),
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {

        //console.log(`onSubmit, mic = ${data.mic}, startRow = ${data.startRow}, endRow = ${data.endRow}`);

        setImportedStocks([]);
        setSubmittedStartRow(data.startRow);
        setSubmittedEndRow(data.endRow);

        return stockService.loadSmmStocks(data)
            .then((stocks) => {
                setImportedStocks(stocks);
            })
            .catch(alertService.error);
    }

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>Import Stocks from Stock Market MBA</h1>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>MIC</label>
                    <input type="text" {...register('mic')} className={`form-control ${errors.mic ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.mic?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Start Row</label>
                    <input type="text" {...register('startRow')} className={`form-control ${errors.startRow ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.startRow?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>End Row</label>
                    <input type="text" {...register('endRow')} className={`form-control ${errors.endRow ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.endRow?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Proceed
                </button>
                <Link to='/' className="btn btn-link">Cancel</Link>
            </div>
            {importedStocks && importedStocks.length > 0 &&
            <div className="form-row">
                <label>Loaded [{importedStocks.length}] stocks</label>
            </div>
            }
            {importedStocks && submittedStartRow &&
            <div className="form-row">
                <label>startRow = {submittedStartRow}</label>
            </div>
            }
            {importedStocks && submittedEndRow &&
            <div className="form-row">
                <label>endRow = {submittedEndRow}</label>
            </div>
            }
            {importedStocks && importedStocks.map((stock, index) => {
                //console.log("index =", index, "figi =", figi);
                let stockStr = `Stock [${index}]: [${stock.stockGuid}] [${stock.tickerSymbol}] [${stock.name}] [${stock.mic}] [${stock.figi}] [${stock.securityType}] [${stock.currencyCode}] [${stock.countryCode}] [${stock.cik}]`;
                if (stock.company)
                    stockStr += `, Company: [${stock.company.companyGuid}] [${stock.company.name}]`;
                return (
                    <div className="form-row" key={stock.stockGuid}>
                        <label>{stockStr}</label>
                    </div>
                );
            })}
        </form>
    );
}

export default ImportStocksSMM;