import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ListStocks from './ListStocks';
import AddEditStock from './AddEditStock';

function AllStocks({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={ListStocks} />
            <Route path={`${path}/add`} component={AddEditStock} />
            <Route path={`${path}/edit/:id`} component={AddEditStock} />
        </Switch>
    );
}

export default AllStocks;