import React from 'react';
import styled from 'styled-components';

const TextField = styled.input`
  height: 32px;
  width: 650px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const GoButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onTextChange, onFilter, onClear }) => (
    <>
      <TextField id="search" type="text" placeholder="Filter Query" aria-label="Search Data" value={filterText} onChange={onTextChange} />
      <GoButton type="button" onClick={onFilter}>{'\u2713'}</GoButton>
      <ClearButton type="button" onClick={onClear}>{'\u2717'}</ClearButton>
    </>
);

export default FilterComponent ;