import React, { useState, useEffect } from "react";

import moment from 'moment';
import 'moment-timezone';

import AuthenticationService from '../../../services/security/AuthenticationService';

import SockJsClient from 'react-stomp';

import memoize from 'memoize-one';
import DataTable from "react-data-table-component";

import FilterComponent from '../../base/FilterComponent';
import ExpandedComponent from '../../base/ExpandedComponent';

import { stockService } from '../../../services/common/stock_service';
import { stockListService } from '../../../services/technical/stock_list_service';

import { formatUtilities } from '../../../utilities/format_utilities';
import { tableStyling } from '../../../utilities/table_styling';

const FINAPI_URL = process.env.REACT_APP_FINAPI_URL;
const WEBSOCKET_URL = `${FINAPI_URL}/ws-stomp`;

const timeZone = 'America/Los_Angeles';

const columns = memoize(() => [
    {
        name: 'FIGI',
        selector: 'figi',
        sortable: false,
        omit: true,
    },
    {
        name: 'Ticker',
        selector: 'tickerSymbol',
        sortable: true,
    },
    {
        name: 'Price',
        selector: (row, index) => formatUtilities.formatCurrency(tableStyling.selectMarketSessionData(row).price, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.marketSessionStyling('#edefff', '#ffedf3'),
        style: {
          fontWeight: 'bold',
        },
    },
    {
        name: 'Price △',
        selector: (row, index) => formatUtilities.formatCurrency(tableStyling.selectMarketSessionData(row).priceChange, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.priceChangeStyling('#edefff', '#ffedf3'),
    },
    {
        name: 'Day %',
        selector: (row, index) => formatUtilities.formatPercent(tableStyling.selectMarketSessionData(row).changePercent),
        sortable: true, right: true,
        conditionalCellStyles: tableStyling.changePercentStyling(0.05, '#edefff', '#ffedf3'),
    },
    {
        name: 'Day Low',
        selector: (row, index) => formatUtilities.formatCurrency(row.dayLow, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.withinCertainPercentStyling('price', 'dayLow', 0.01, '#e5f7ec'),
    },
    {
        name: 'Day High',
        selector: (row, index) => formatUtilities.formatCurrency(row.dayHigh, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.withinCertainPercentStyling('price', 'dayHigh', 0.01, '#ffeded'),
    },
    {
        name: 'TTM Low',
        selector: (row, index) => formatUtilities.formatCurrency(row.ttmLow, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.withinCertainPercentStyling('price', 'ttmLow', 0.03, '#e5f7ec'),
    },
    {
        name: 'TTM High',
        selector: (row, index) => formatUtilities.formatCurrency(row.ttmHigh, row.currencyCode),
        sortable: false, right: true,
        conditionalCellStyles: tableStyling.withinCertainPercentStyling('price', 'ttmHigh', 0.03, '#ffeded'),
    },
    {
        name: 'Volume',
        selector: (row, index) => formatUtilities.formatLongInteger(row.volume),
        sortable: false, right: true,
    },
    {
        name: 'Last Trade',
        selector: (row, index) => row.readDate? moment(row.readDate).tz(timeZone).format("h:mm:ss A z") : '',
        sortable: true, right: true,
        conditionalCellStyles: tableStyling.marketSessionStyling('#edefff', '#ffedf3'),
    },
]);

function WatchStockList({ props, history, match }) {

  const { listName, dateStr } = match.params;

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [filterToggle, setFilterToggle] = useState(false);

  const filteredData = data.filter(stockIntraday => stockIntraday.tickerSymbol && stockIntraday.tickerSymbol.toLowerCase().includes(filterText.toLowerCase()));

  const handleFilterTextChange = event => {
    setFilterText(event.target.value);
  };

  const subHeaderComponentMemo = React.useMemo(() => {

    const handleFilter = () => {
      setFilterToggle(!filterToggle);
    };

    const handleClear = () => {
      if (filterText) {
        setFilterText('');
      }
    };

    return <FilterComponent filterText={filterText} onTextChange={handleFilterTextChange} onFilter={handleFilter} onClear={handleClear} />;
  }, [filterText, filterToggle]);

  const onConnected = () => {
    console.log(`WebSocket connected to ${WEBSOCKET_URL}`);
  };

  const onConnectFailed = (error) => {
    console.log(`WebSocket failed to connect to ${WEBSOCKET_URL}, error =`, error);
  };

  const onDisconnected = () => {
    console.log(`WebSocket disconnected from ${WEBSOCKET_URL}`);
  };

  const onMessageReceived = (stockIntradayData) => {
    //console.log('Message received on WebSocket, stockIntradayData =', stockIntradayData);

    // create a Map from stockIntradayData 
    let stockIntradayMap  = new Map();
    stockIntradayData.stockIntradays.forEach( stockIntraday => {
      stockIntradayMap.set(stockIntraday.figi, stockIntraday);
    });

    // loop thru data for the order, filling in the stockIntraday data
    let newData = [];
    data.forEach( stockIntraday => {
      if (stockIntradayMap.has(stockIntraday.figi)) {
        newData = [...newData, stockIntradayMap.get(stockIntraday.figi)];
      }
      else {
        newData = [...newData, stockIntraday];
      }
    });

    setData(newData);
  };

  const fetchStockList = async () => {
    setLoading(true);

    stockListService.getById(listName, dateStr).then(stockList => {

      //console.log("stockList =", stockList);

      // get tickerSymbols for the FIGIs
      let stocksWithFigi = [];
      stockList.figis.forEach(figi => stocksWithFigi = [...stocksWithFigi, {"figi": figi}]);
      stockService.findByFIGIs(stocksWithFigi).then(stocks => {
          //console.log("stocks =", stocks);

          // get initial data/rows from the stocks
          let initialData = [];
          stocks.forEach(stock => {
            initialData = [...initialData, {'figi':stock.figi, 'tickerSymbol':stock.tickerSymbol}]
          });

          setData(initialData);
          setLoading(false);
      });
    });
  };

  useEffect(() => {
    fetchStockList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="WatchStockList">
        <SockJsClient
          url={WEBSOCKET_URL}
          headers={AuthenticationService.getStompConnectionHeaders()}
          topics={[`/topic/data_import_stock_intraday.${listName}`]}
          onConnect={onConnected}
          onConnectFailure={onConnectFailed}
          onDisconnect={onDisconnected}
          onMessage={msg => onMessageReceived(msg)}
          debug={false}
        />
        <DataTable
          title={listName}
          columns={columns()}
          data={filteredData}
          progressPending={loading}
          keyField='figi'
          striped
          highlightOnHover
          fixedHeader
          fixedHeaderScrollHeight='78vh'
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          expandableRows
          expandableRowsComponent={<ExpandedComponent />}
        />
    </div>
  );
}

export default WatchStockList;