import React, { useState, useEffect } from "react";
import Alert from './Alert';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { FaBars } from "react-icons/fa";

import FinanceSidebar from "./FinanceSidebar";
import Settings from "./Settings";
import Dashboard from "./Dashboard";
import WorldTimes from "./WorldTimes";
import AllExchanges from "../common/exchanges/AllExchanges";
import AllCompanies from "../common/companies/AllCompanies";
import AllStocks from "../common/stocks/AllStocks";
import AllStockLists from "../technical/stockLists/AllStockLists";
import AllUsers from "../security/users/AllUsers";
import AuthenticatedRoute from '../security/AuthenticatedRoute';
import ImportStocksSMM from "../importExport/stocks/ImportStocksSMM";
import ImportStocksSmmWs from "../importExport/stocks/ImportStocksSmmWs";
// import ImportStocksPolygon from "../import/stocks/ImportStocksPolygon";
import LoginComponent from '../security/LoginComponent';
import LogoutComponent from '../security/LogoutComponent';

function Layout({ setLocale, children }) {
  const [rtl, setRtl] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(false);
  const [toggled, setToggled] = useState(true);
  const [userLoggedIn, setUserLoggedIn] = useState(false);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    // setLocale(checked ? 'ar' : 'en');
  };
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleUserLoggedIn = (value) => {
    setUserLoggedIn(value);
  };
  
  const redoLayoutWithUserLoggedIn = async () => {
    console.log("in Layout::redoLayoutWithUserLoggedIn(), userLoggedIn =", userLoggedIn);
  }
  
  useEffect(() => {
    redoLayoutWithUserLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLoggedIn]);

  return (
    <Router>
      <div className={`app ${rtl ? "rtl" : ""} ${toggled ? "toggled" : ""}`}>
        {userLoggedIn &&
          <FinanceSidebar
            image={image}
            collapsed={collapsed}
            rtl={rtl}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            handleCollapsedChange={handleCollapsedChange}
          />
        }
        <main>
            {userLoggedIn && !toggled && (
              <div className="block" onClick={() => handleToggleSidebar(true)}>
                <FaBars />
              </div>
            )}

            <div className="app-container">
              <Alert />
              {/* A <Switch> looks through its children <Route>s and
                renders the first one that matches the current URL. */}
              <Switch>
                <Route path="/login" exact >
                  <LoginComponent handleUserLoggedIn={handleUserLoggedIn} />
                </Route>
                <AuthenticatedRoute path="/logout" exact >
                  <LogoutComponent handleUserLoggedIn={handleUserLoggedIn} />
                </AuthenticatedRoute>
                <AuthenticatedRoute path="/" exact component={Dashboard} />
                <AuthenticatedRoute path="/dashboard" exact component={Dashboard} />
                <AuthenticatedRoute path="/users" component={AllUsers} />
                <AuthenticatedRoute path="/import/stocks/SMM" exact component={ImportStocksSMM} />
                <AuthenticatedRoute path="/import/stocks/StockMarketMBA" exact component={ImportStocksSmmWs} />
                {/* <AuthenticatedRoute path="/import/stocks/Polygon" exact component={ImportStocksPolygon} /> */}
                <AuthenticatedRoute path="/worldTimes" exact component={WorldTimes} />
                <AuthenticatedRoute path="/stockLists" component={AllStockLists} />
                <AuthenticatedRoute path="/securityExchanges" component={AllExchanges} />
                <AuthenticatedRoute path="/companies" component={AllCompanies} />
                <AuthenticatedRoute path="/stocks" component={AllStocks} />
                <AuthenticatedRoute path="/settings" exact >
                  <Settings
                    image={image}
                    toggled={toggled}
                    collapsed={collapsed}
                    rtl={rtl}
                    handleToggleSidebar={handleToggleSidebar}
                    handleCollapsedChange={handleCollapsedChange}
                    handleRtlChange={handleRtlChange}
                    handleImageChange={handleImageChange}
                  />
                </AuthenticatedRoute>
              </Switch>
            </div>
            <footer>
              <small>© {new Date().getFullYear()} LMental Industries</small>
            </footer>
        </main>
      </div>
    </Router>
  );
}

export default Layout;
