import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { userService } from '../../../services/common/user_service';

function AddEditUser({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;

    //console.log("isAddMode =", isAddMode, "match =", match);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        userName: Yup.string()
            .required('Username is required'),
        firstName: Yup.string()
            .required('First Name is required'),
        lastName: Yup.string()
            .required('Last Name is required'),
        email: Yup.string()
            .email('Email is invalid')
            .required('Email is required'),
        roles: Yup.string()
            .required('Roles is required'),
        password: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .concat(isAddMode ? Yup.string().required('Password is required') : null)
            .min(6, 'Password must be at least 6 characters'),
        confirmPassword: Yup.string()
            .transform(x => x === '' ? undefined : x)
            .when('password', (password, schema) => {
                if (password || isAddMode) return schema.required('Confirm Password is required');
            })
            .oneOf([Yup.ref('password')], 'Passwords must match')
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        return isAddMode
            ? createUser(data)
            : updateUser(id, data);
    }

    function createUser(data) {
        return userService.create(data)
            .then((user) => {
                alertService.success(`User ${user.userName} added`, { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function updateUser(id, data) {
        return userService.update(id, data)
            .then((user) => {
                alertService.success(`User ${user.userName} updated`, { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(
                (message, options) => {
                    alertService.error(message, {...options, autoClose: false})
                }
            );
    }

    const [user, setUser] = useState({});
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        if (!isAddMode) {
            // get user and set form fields
            userService.getById(id).then(user => {

                console.log("user =", user);

                const fields = ['userGuid','userName', 'firstName', 'lastName', 'email', 'roles', 'active','createdBy','createdDate'];
                fields.forEach(field => setValue(field, user[field]));
                setUser(user);
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>{isAddMode ? 'Add User' : 'Edit User'}</h1>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>Username</label>
                    <input name="userName" type="text" {...register('userName')} className={`form-control ${errors.userName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.userName?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>First Name</label>
                    <input name="firstName" type="text" {...register('firstName')} className={`form-control ${errors.firstName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.firstName?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Last Name</label>
                    <input name="lastName" type="text" {...register('lastName')} className={`form-control ${errors.lastName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.lastName?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-4">
                    <label>Email</label>
                    <input name="email" type="text" {...register('email')} className={`form-control ${errors.email ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Roles</label>
                    <input name="roles" type="text" {...register('roles')} className={`form-control ${errors.roles ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.roles?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>Active</label>
                    <input name="active" type="checkbox" {...register('active')} className={`form-control ${errors.active ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.active?.message}</div>
                </div>
            </div>
            {!isAddMode &&
                <div>
                    <h3 className="pt-3">Change Password</h3>
                    <p>Leave blank to keep the same password</p>
                </div>
            }
            <div className="form-row">
                <div className="form-group col-4">
                    <label>
                        Password
                        {!isAddMode &&
                            (!showPassword
                                ? <span> - <a onClick={() => setShowPassword(!showPassword)} className="text-primary">Show</a></span>
                                : <em> - {user.password}</em>
                            )
                        }
                    </label>
                    <input name="password" type="password" {...register('password')} className={`form-control ${errors.password ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                </div>
                <div className="form-group col-4">
                    <label>Confirm Password</label>
                    <input name="confirmPassword" type="password" {...register('confirmPassword')} className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.confirmPassword?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
            <input name="userGuid" type="hidden" {...register('userGuid')} />
            <input name="createdBy" type="hidden" {...register('createdBy')} />
            <input name="createdDate" type="hidden" {...register('createdDate')} />
        </form>
    );
}

export default AddEditUser;