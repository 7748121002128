import React, { Component } from 'react'

import { Link } from 'react-router-dom';

import AuthenticationService from '../../services/security/AuthenticationService';

class LogoutComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            handleUserLoggedIn: props.handleUserLoggedIn
        }
    }

    render() {
        
        AuthenticationService.logout();

        return (
            <>
                <h1>You are logged out</h1>
                <div className="container">
                    <div className="form-row">
                        <hr />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            Thanks for using LMental Finance.
                        </div>
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            Click <Link to='/login' className="btn btn-link">here</Link> to log back in.
                        </div>
                    </div>
                </div>
            </>
        )
    }

    componentDidMount() {
        this.state.handleUserLoggedIn(false);
    }
}

export default LogoutComponent