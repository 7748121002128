import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ListExchanges from './ListExchanges';
import AddEditExchange from './AddEditExchange';

function AllExchanges({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={ListExchanges} />
            <Route path={`${path}/add`} component={AddEditExchange} />
            <Route path={`${path}/edit/:id`} component={AddEditExchange} />
        </Switch>
    );
}

export default AllExchanges;