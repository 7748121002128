const intlMessages = {
  en: {
    // FinanceSidebar component

    sidebarTitle: "LMental Finance",
    sidebarTitleCompact: "LMF",

    dashboard: "Dashboard",
    controlPanel: "Control Panel",

    users: "Users",
    allUsers: "All Users",
    addUser: "Add User",
    worldTimes: "World Times",

    import: "Import",

    data: "Data",

    portfolio: "Portfolio",
    stockLists: "Stock Lists",
    allStockLists: "All Stock Lists",

    securitiesExchange: "Securities / Exchange",
    exchanges: "Exchanges",
    companies: "Companies",
    stocks: "Stocks",
    allStocks: "All Stocks",

    logout: "Logout",

    settings: "Settings",

    // Dashboard component

    currentLocalDate: "Current Local Date",
    currentUtcDate: "Current UTC Date",

    dashboardTitle: "LMental Finance Dashboard",
    loggedInUserName: "Username",
    totalExchanges: "# of Security Exchanges",
    totalCompanies: "# of Companies",
    totalStocks: "# of Stocks",

    // Settings component

    settingsTitle: "LMental Finance Settings",
    settingsDescription:
      "LMental Finance web application configuration settings",

    collapsed: "Collapsed",
    rtl: "RTL",
    image: "Background image",
    new: "NEW",
  },
};

export default intlMessages;
