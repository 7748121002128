import React from 'react';
import { Route, Switch } from 'react-router-dom';

import ListStockLists from './ListStockLists';
import AddEditStockList from './AddEditStockList';
import WatchStockList from './WatchStockList';

function AllStockLists({ match }) {
    const { path } = match;
    
    return (
        <Switch>
            <Route exact path={path} component={ListStockLists} />
            <Route path={`${path}/add`} component={AddEditStockList} />
            <Route path={`${path}/edit/:listName/:dateStr`} component={AddEditStockList} />
            <Route path={`${path}/watch/:listName/:dateStr`} component={WatchStockList} />
        </Switch>
    );
}

export default AllStockLists;