import React, { useEffect } from 'react';

import moment from 'moment';

import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { stockListService } from '../../../services/technical/stock_list_service';
import { stockService } from '../../../services/common/stock_service';

function AddEditStockList({ history, match }) {

    const { listName, dateStr } = match.params;
    const isAddMode = !listName;

    const [figis, setFigis] = React.useState([]);
    const [stocks, setStocks] = React.useState([]);

    //console.log("isAddMode =", isAddMode, "match =", match);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        listName: Yup.string().required('Stock List Name is required'),
        date: Yup.string().required('Date is required'),
        provider: Yup.string().required('Provider is required')
    });

    // functions to build form returned by useForm() hook
    const { register, unregister, handleSubmit, reset, getValues, setValue, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        return isAddMode
            ? createStockList(data)
            : updateStockList(listName, dateStr, data);
    }

    function createStockList(data) {
        return stockListService.create(data)
            .then((stockList) => {
                const stockListDateStr = moment(stockList.date).utc().format("YYYY-MM-DD");
                alertService.success(`Stock List [${stockList.listName}, ${stockListDateStr}] added`, { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function updateStockList(listName, dateStr, data) {
        return stockListService.update(listName, dateStr, data)
            .then((stockList) => {
                alertService.success(`Stock List [${listName}, ${dateStr}] updated`, { keepAfterRouteChange: true });
                history.push('../..');
            })
            .catch(
                (message, options) => {
                    alertService.error(message, {...options, autoClose: false})
                }
            );
    }

    useEffect(() => {
        if (!isAddMode) {
            // set form fields from existingStockList
            stockListService.getById(listName, dateStr).then(stockList => {

                console.log("stockList =", stockList);

                // get tickerSymbols for the FIGIs
                let stocksWithFigi = [];
                stockList.figis.forEach(figi => stocksWithFigi = [...stocksWithFigi, {"figi": figi}]);
                stockService.findByFIGIs(stocksWithFigi).then(stocks => {
                    //console.log("stocks =", stocks);

                    // get ordered FIGIs from the stocks
                    let orderedFigis = [];
                    stocks.forEach(stock => orderedFigis = [...orderedFigis, stock.figi]);

                    setStocks(stocks);
                    setFigis(orderedFigis);

                    const fields = ['listName', 'provider', 'createdBy', 'createdDate', 'modifiedBy', 'modifiedDate'];
                    fields.forEach(field => setValue(field, stockList[field]));
        
                    const stockListDateStr = moment(stockList.date).utc().format("YYYY-MM-DD");
                    setValue('date', stockListDateStr);

                    orderedFigis.map( (figi, index) => setValue(`figis[${index}]`, figi) );
                });
            });
        }
        // eslint-disable-next-line
    }, []);

    const addFigi = () => {
        setStocks(prevStocks => [...prevStocks, {"figi": ''}]);
        setFigis(prevFigis => [...prevFigis, '']);
    };

    const removeFigi = removeIndex => () => {
        // get current Figis except for one at removeIndex
        let currentFigis = [];
        figis.map((figi, index) => {
            if (index !== removeIndex)
                currentFigis = [...currentFigis, getValues('figis['+index+']')];
            return 0;
        });

        console.log('currentFigis =', currentFigis);

        // update form values
        currentFigis.map((figi, index) => {
            setValue(`figis[${index}]`, figi);
            return 0;
        });

        // unregister last figi register
        const lastIndex = figis.length - 1;
        unregister(`figis[${lastIndex}]`);

        // remove stock at removeIndex
        setStocks(prevStocks => [...prevStocks.filter((stock, index) => index !== removeIndex)]);

        // set figis state to current
        setFigis(currentFigis);
    };

    const lookupFigi = index => () => {
        //console.log(`lookupFigi: index [${index}]`);
        //console.log(`getValues(figis[${index}])`, getValues('figis['+index+']'));
        
        const figi = getValues('figis['+index+']');

        stockService.getByFigi(figi).then(stock => {

            //console.log(`stock with FIGI[${figi}] =`, stock);
            
            stocks[index] = stock;

            // set updated stocks
            setStocks(stocks);

            // get current Figis
            let currentFigis = [];
            figis.forEach((figi, index) => {
                currentFigis = [...currentFigis, getValues('figis['+index+']')];
            });

            // set figis state to current
            setFigis(currentFigis);
        });
    };

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>{isAddMode ? 'Add Stock List' : 'Edit Stock List'}</h1>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>List Name</label>
                    <input type="text" {...register('listName')} className={`form-control ${errors.listName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.listName?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Date</label>
                    <input type="text" {...register('date')} className={`form-control ${errors.date ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.date?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Provider</label>
                    <input type="text" {...register('provider')} className={`form-control ${errors.provider ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.provider?.message}</div>
                </div>
            </div>
            {figis.map((figi, index) => {
                //console.log("index =", index, "figi =", figi);
                const fieldName = `figis[${index}]`;
                return (
                    <div className="form-row">
                        <div className="form-group col-2">
                            <label>FIGI {index + 1}</label>
                            <input type="text" key={fieldName} {...register(fieldName)} className={`form-control ${errors.figis ? 'is-invalid' : ''}`} />
                        </div>
                        <div className="form-group col-2">
                            <label>Ticker Symbol {index + 1}</label>
                            <label>{stocks[index]?.tickerSymbol}</label>
                        </div>
                        <div className="form-group col-1">
                            <button type="button" onClick={lookupFigi(index)} disabled={formState.isSubmitting} className="btn btn-info">
                                Lookup
                            </button>
                            <button type="button" onClick={removeFigi(index)} disabled={formState.isSubmitting} className="btn btn-secondary">
                                Remove
                            </button>
                        </div>
                    </div>
                );
            })}
            <div className="form-group">
                <button type="button" onClick={addFigi} disabled={formState.isSubmitting} className="btn btn-success">
                    Add FIGI
                </button>
                <div className="invalid-feedback">{errors.figis?.message}</div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '../..'} className="btn btn-link">Cancel</Link>
            </div>
            <input type="hidden" {...register('createdBy')} />
            <input type="hidden" {...register('createdDate')} />
            <input type="hidden" {...register('modifiedBy')} />
            <input type="hidden" {...register('modifiedDate')} />
        </form>
    );
}

export default AddEditStockList;