import axios from 'axios'
import jwt_decode from "jwt-decode";

const API_URL = process.env.REACT_APP_FINAPI_URL;

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const JWT_TOKEN_SESSION_ATTRIBUTE_NAME = 'jwtToken'

export const ROLES_CLAIM_NAME = 'roles'
export const ROLES_SESSION_ATTRIBUTE_NAME = 'roles'

class AuthenticationService {

    executeBasicAuthenticationService(userName, password) {
        return axios.get(`${API_URL}/basicauth`,
            { headers: { authorization: this.createBasicAuthToken(userName, password) } })
    }

    executeJwtAuthenticationService(userName, password) {
        //console.log("executeJwtAuthenticationService, userName =", userName);
        return axios.post(`${API_URL}/authenticate`, {
            userName,
            password
        })
    }

    createBasicAuthToken(userName, password) {
        return 'Basic ' + window.btoa(userName + ":" + password)
    }

    createJWTToken(token) {
        //console.log("createJWTToken, token =", token);
        return 'Bearer ' + token
    }

    registerSuccessfulLogin(userName, password) {
        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, userName)
        this.setupAxiosInterceptors(this.createBasicAuthToken(userName, password))
    }

    registerSuccessfulLoginForJwt(userName, token) {
        //console.log("registerSuccessfulLoginForJwt, userName =", userName)
        //console.log("registerSuccessfulLoginForJwt, token =", token)

        sessionStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, userName)
        sessionStorage.setItem(JWT_TOKEN_SESSION_ATTRIBUTE_NAME, token)

        // extract and set roles in sessionStorage
        console.log("jwt_decode(token) =", jwt_decode(token))
        const roles = jwt_decode(token).roles
        console.log("roles =", roles)
        sessionStorage.setItem(ROLES_SESSION_ATTRIBUTE_NAME, roles)

        this.setupAxiosInterceptors()
    }

    isUserLoggedIn() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return false
        return true
    }

    getLoggedInUserName() {
        let user = sessionStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    getLoggedInJwtToken() {
        let token = sessionStorage.getItem(JWT_TOKEN_SESSION_ATTRIBUTE_NAME)
        if (token === null) return ''
        return token
    }

    getStompConnectionHeaders() {
        const stompConnectionHeaders = {
          "X-Authorization": "Bearer " + this.getLoggedInJwtToken(),
        }
        return stompConnectionHeaders
    }

    logout() {
        sessionStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        sessionStorage.removeItem(JWT_TOKEN_SESSION_ATTRIBUTE_NAME)

        //console.log("logout, isUserLoggedIn =", this.isUserLoggedIn());
    }

    hasRole(role) {
        let rolesStr = sessionStorage.getItem(ROLES_SESSION_ATTRIBUTE_NAME)
        if (rolesStr === null || rolesStr === "") return false

        let roles = rolesStr.split(",")

        return roles.includes(role)
    }

    setupAxiosInterceptors() {
        
        axios.interceptors.request.use(
            (config) => {
                const token = sessionStorage.getItem(JWT_TOKEN_SESSION_ATTRIBUTE_NAME)
                //console.log("axios request interceptor, url =", config.url, "method =", config.method, "token =", token);
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = this.createJWTToken(token)
                }
                return config
            }
        )
    }
}

const authenticationService = new AuthenticationService();

export default authenticationService;