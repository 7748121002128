import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useIntl } from 'react-intl';
import { RiCalendar2Fill, RiGovernmentLine } from 'react-icons/ri';
import { FaRegCalendarAlt, FaRegUser, FaRegBuilding, FaMoneyCheckAlt } from 'react-icons/fa';

import AuthenticationService from '../../services/security/AuthenticationService';

const Dashboard = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
}) => {
  const intl = useIntl();
  
  const FINAPI_URL = process.env.REACT_APP_FINAPI_URL;

  const [loggedInUserName, setLoggedInUserName] = useState(0);
  const [exchangesCount, setExchangesCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [stocksCount, setStocksCount] = useState(0);

  const fetchCounts = async () => {
  
    const isUserLoggedIn = AuthenticationService.isUserLoggedIn();
    console.log("in Dashboard, isUserLoggedIn =", isUserLoggedIn);
    
    if (!isUserLoggedIn)
      return;

    setLoggedInUserName(AuthenticationService.getLoggedInUserName());

    const exchangesCountResponse = await axios.get(
        `${FINAPI_URL}/SecurityExchange/count`
    )
    .catch(
      function (error) {
        if (error.response) {
          // Request made and server responded
          console.log("Error making server call: server responded with, response = ", error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.log("Error making server call: no response was received, request = ", error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error making server call: problem setting up request, message = ', error.message);
        };
      }
    );

    //console.log('exchangesCountResponse', exchangesCountResponse);
    setExchangesCount(exchangesCountResponse.data.count);
    
    const companiesCountResponse = await axios.get(
      `${FINAPI_URL}/Company/count`
    );
    //console.log('companiesCountResponse', companiesCountResponse);
    setCompaniesCount(companiesCountResponse.data.count);
    
    const stocksCountResponse = await axios.get(
        `${FINAPI_URL}/Stock/count`
    );
    //console.log('stocksCountResponse', stocksCountResponse);
    setStocksCount(stocksCountResponse.data.count);
  }

  useEffect(() => {
    fetchCounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <header>
        <h1>
          {intl.formatMessage({ id: 'dashboardTitle' })}
        </h1>
      </header>
      <div className="dashboard-value">
        <FaRegCalendarAlt />
        <span>{intl.formatMessage({ id: 'currentLocalDate' })}: {(new Date(Date.now())).toString()}</span>
      </div>
      <div className="dashboard-value">
        <RiCalendar2Fill />
        <span>{intl.formatMessage({ id: 'currentUtcDate' })}: {(new Date(Date.now())).toUTCString()}</span>
      </div>
      <div className="dashboard-value">
        <FaRegUser />
        <span>{intl.formatMessage({ id: 'loggedInUserName' })}: {loggedInUserName}</span>
      </div>
      <div className="dashboard-value">
        <RiGovernmentLine />
        <span>{intl.formatMessage({ id: 'totalExchanges' })}: {exchangesCount}</span>
      </div>
      <div className="dashboard-value">
        <FaRegBuilding />
        <span>{intl.formatMessage({ id: 'totalCompanies' })}: {companiesCount}</span>
      </div>
      <div className="dashboard-value">
        <FaMoneyCheckAlt />
        <span>{intl.formatMessage({ id: 'totalStocks' })}: {stocksCount}</span>
      </div>
    </div>
  );
};

export default Dashboard;