import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { companyService } from '../../../services/common/company_service';

function AddEditCompany({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;

    //console.log("isAddMode =", isAddMode, "match =", match);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required('Company Name is required'),
        city: Yup.string()
            .required('City is required'),
        country: Yup.string()
            .required('Country is required'),
        countryCode: Yup.string()
            .required('Country Code is required')
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        return isAddMode
            ? createCompany(data)
            : updateCompany(id, data);
    }

    function createCompany(data) {
        return companyService.create(data)
            .then((company) => {
                alertService.success(`Company ${company.name} added`, { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function updateCompany(id, data) {
        return companyService.update(id, data)
            .then((company) => {
                alertService.success(`Company ${company.name} updated`, { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(
                (message, options) => {
                    alertService.error(message, {...options, autoClose: false})
                }
            );
    }

    useEffect(() => {
        if (!isAddMode) {
            // get company and set form fields
            companyService.getById(id).then(company => {

                console.log("company =", company);

                const fields = ['companyGuid','name', 'cik', 'lei', 'bics', 'streetAddress', 'suiteFloor',
                    'city','state', 'zip', 'country', 'countryCode', 'mainPhone', 'companyDesc', 'gicsSectorName', 'gicsIndustryName', 'sicId',
                    'createdBy','createdDate'];
                fields.forEach(field => setValue(field, company[field]));
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>{isAddMode ? 'Add Company' : 'Edit Company'}</h1>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>Company Name</label>
                    <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>CIK</label>
                    <input name="cik" type="text" {...register('cik')} className={`form-control ${errors.cik ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.cik?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>LEI</label>
                    <input name="lei" type="text" {...register('lei')} className={`form-control ${errors.lei ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.lei?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-6">
                    <label>BICS</label>
                    <input name="bics" type="text" {...register('bics')} className={`form-control ${errors.bics ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.bics?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-5">
                    <label>Street Address</label>
                    <input name="streetAddress" type="text" {...register('streetAddress')} className={`form-control ${errors.streetAddress ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.streetAddress?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Suite / Floor</label>
                    <input name="suiteFloor" type="text" {...register('suiteFloor')} className={`form-control ${errors.suiteFloor ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.suiteFloor?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>City</label>
                    <input name="city" type="text" {...register('city')} className={`form-control ${errors.city ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.city?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>State</label>
                    <input name="state" type="text" {...register('state')} className={`form-control ${errors.state ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.state?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Zip</label>
                    <input name="zip" type="text" {...register('zip')} className={`form-control ${errors.zip ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.zip?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>Country</label>
                    <input name="country" type="text" {...register('country')} className={`form-control ${errors.country ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.country?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Country Code</label>
                    <input name="countryCode" type="text" {...register('countryCode')} className={`form-control ${errors.countryCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.countryCode?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Phone</label>
                    <input name="mainPhone" type="text" {...register('mainPhone')} className={`form-control ${errors.mainPhone ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.mainPhone?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-3">
                    <label>GICS Sector</label>
                    <input name="gicsSectorName" type="text" {...register('gicsSectorName')} className={`form-control ${errors.gicsSectorName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.gicsSectorName?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>GICS Industry</label>
                    <input name="gicsIndustryName" type="text" {...register('gicsIndustryName')} className={`form-control ${errors.gicsIndustryName ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.gicsIndustryName?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>SIC</label>
                    <input name="sicId" type="text" {...register('sicId')} className={`form-control ${errors.sicId ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.sicId?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-8">
                    <label>Description</label>
                    <textarea name="companyDesc" rows="9" cols="60" {...register('companyDesc')} className={`form-control ${errors.companyDesc ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.companyDesc?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
            <input name="companyGuid" type="hidden" {...register('companyGuid')} />
            <input name="createdBy" type="hidden" {...register('createdBy')} />
            <input name="createdDate" type="hidden" {...register('createdDate')} />
        </form>
    );
}

export default AddEditCompany;