import React from 'react';

import moment from 'moment';
import 'moment-timezone';

import ReactCountryFlag from "react-country-flag"

import { useIntl } from 'react-intl';

const WorldTimes = () => {

  const intl = useIntl();

  const dateTimeFormat = 'YYYY-MM-DD hh:mm:ss A Z (z)';

  const worldTimes = [
    {
      countryCode: 'US',
      location: 'Los Angeles, U.S.A.',
      timeZone: 'America/Los_Angeles'
    },
    {
      countryCode: 'US',
      location: 'New York, U.S.A.',
      timeZone: 'America/New_York'
    },
    {
      countryCode: 'GB',
      location: 'London, United Kingdom',
      timeZone: 'Europe/London'
    },
    {
      countryCode: 'FR',
      location: 'Paris, France',
      timeZone: 'Europe/Paris'
    },
    {
      countryCode: 'TW',
      location: 'Taipei, Taiwan',
      timeZone: 'Asia/Taipei'
    },
    {
      countryCode: 'JP',
      location: 'Tokyo, Japan',
      timeZone: 'Asia/Tokyo'
    },
    {
      countryCode: 'AU',
      location: 'Melbourne, Australia',
      timeZone: 'Australia/Melbourne'
    }
  ];

  return (
    <div>
      <header>
        <h1>
          {intl.formatMessage({ id: 'worldTimes' })}
        </h1>
      </header>
      <table>
      {worldTimes.map((worldTime, index) => {
        //console.log("index =", index, "worldTime =", worldTime);
        return (
          <tr className="world-time">
            <td className="world-time">
              <ReactCountryFlag
                  countryCode={worldTime.countryCode}
                  svg
                  title={worldTime.countryCode}
              />
            </td>
            <td className="world-time">
              <span>{worldTime.location}</span>
            </td>
            <td className="world-time">
              <span>{moment().tz(worldTime.timeZone).format(dateTimeFormat)}</span>
            </td>
          </tr>
        );
      })}
      </table>
    </div>
  );
};

export default WorldTimes;