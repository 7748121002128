import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { alertService } from '../../../services/common/alert_service';
import { stockService } from '../../../services/common/stock_service';

function AddEditStock({ history, match }) {
    const { id } = match.params;
    const isAddMode = !id;

    //console.log("isAddMode =", isAddMode, "match =", match);
    
    // form validation rules 
    const validationSchema = Yup.object().shape({
        tickerSymbol: Yup.string().required('Ticker Symbol is required'),
        name: Yup.string().required('Stock Name is required'),
        figi: Yup.string().required('FIGI is required'),
        compositeFigi: Yup.string().required('Composite FIGI is required'),
        shareClassFigi: Yup.string().required('Share Class FIGI is required'),
        figiUid: Yup.string().required('FIGI UID is required'),
        securityType: Yup.string().required('Security Type is required'),
        market: Yup.string().required('Market is required'),
        currencyCode: Yup.string().required('Currency Code is required'),
        countryCode: Yup.string().required('Country Code is required')
    });

    // functions to build form returned by useForm() hook
    const { register, handleSubmit, reset, setValue, formState, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema)
    });

    function onSubmit(data) {
        return isAddMode
            ? createStock(data)
            : updateStock(id, data);
    }

    function createStock(data) {
        return stockService.create(data)
            .then((stock) => {
                alertService.success(`Stock ${stock.tickerSymbol} added`, { keepAfterRouteChange: true });
                history.push('.');
            })
            .catch(alertService.error);
    }

    function updateStock(id, data) {
        return stockService.update(id, data)
            .then((stock) => {
                alertService.success(`Stock ${stock.tickerSymbol} updated`, { keepAfterRouteChange: true });
                history.push('..');
            })
            .catch(
                (message, options) => {
                    alertService.error(message, {...options, autoClose: false})
                }
            );
    }

    useEffect(() => {
        if (!isAddMode) {
            // get stock and set form fields
            stockService.getById(id).then(stock => {

                console.log("stock =", stock);

                const fields = ['stockGuid', 'tickerSymbol','name',
                    'figi', 'compositeFigi', 'shareClassFigi', 'figiUid',
                    'isin', 'cusip', 'sedol', 'cik',
                    'securityType', 'market', 'mic', 'marketCap', 'currencyCode', 
                    'sharesOutstanding','theFloat', 'sharesShort', 'percentInsiders', 'percentInstitutions', 
                    'countryCode', 'active', 'inceptionDate', 'expirationDate',
                    'createdBy','createdDate'];
                fields.forEach(field => setValue(field, stock[field]));
            });
        }
        // eslint-disable-next-line
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit, () => console.log("submit error"))} onReset={reset}>
            <h1>{isAddMode ? 'Add Stock' : 'Edit Stock'}</h1>
            <div className="form-row">
                <div className="form-group col-1">
                    <label>Ticker Symbol</label>
                    <input name="tickerSymbol" type="text" {...register('tickerSymbol')} className={`form-control ${errors.tickerSymbol ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.tickerSymbol?.message}</div>
                </div>
                <div className="form-group col-3">
                    <label>Stock Name</label>
                    <input name="name" type="text" {...register('name')} className={`form-control ${errors.name ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.name?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>FIGI</label>
                    <input name="figi" type="text" {...register('figi')} className={`form-control ${errors.figi ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.figi?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Composite FIGI</label>
                    <input name="compositeFigi" type="text" {...register('compositeFigi')} className={`form-control ${errors.compositeFigi ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.compositeFigi?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Share Class FIGI</label>
                    <input name="shareClassFigi" type="text" {...register('shareClassFigi')} className={`form-control ${errors.shareClassFigi ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.shareClassFigi?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>FIGI UID</label>
                    <input name="figiUid" type="text" {...register('figiUid')} className={`form-control ${errors.figiUid ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.figiUid?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>ISIN</label>
                    <input name="isin" type="text" {...register('isin')} className={`form-control ${errors.isin ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.isin?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>CUSIP</label>
                    <input name="cusip" type="text" {...register('cusip')} className={`form-control ${errors.cusip ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.cusip?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>SEDOL</label>
                    <input name="sedol" type="text" {...register('sedol')} className={`form-control ${errors.sedol ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.sedol?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>CIK</label>
                    <input name="cik" type="text" {...register('cik')} className={`form-control ${errors.cik ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.cik?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-1">
                    <label>Security Type</label>
                    <input name="securityType" type="text" {...register('securityType')} className={`form-control ${errors.securityType ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.securityType?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>Market</label>
                    <input name="market" type="text" {...register('market')} className={`form-control ${errors.market ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.market?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>MIC</label>
                    <input name="market" type="text" {...register('mic')} className={`form-control ${errors.mic ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.mic?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Market Cap</label>
                    <input name="marketCap" type="text" {...register('marketCap')} className={`form-control ${errors.marketCap ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.marketCap?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>Currency Code</label>
                    <input name="currencyCode" type="text" {...register('currencyCode')} className={`form-control ${errors.currencyCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.currencyCode?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-2">
                    <label>Shares Outstanding</label>
                    <input name="sharesOutstanding" type="text" {...register('sharesOutstanding')} className={`form-control ${errors.sharesOutstanding ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.sharesOutstanding?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>The Float</label>
                    <input name="theFloat" type="text" {...register('theFloat')} className={`form-control ${errors.theFloat ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.theFloat?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>Shares Short</label>
                    <input name="sharesShort" type="text" {...register('sharesShort')} className={`form-control ${errors.sharesShort ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.sharesShort?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>% Insiders</label>
                    <input name="percentInsiders" type="text" {...register('percentInsiders')} className={`form-control ${errors.percentInsiders ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.percentInsiders?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>% Institutions</label>
                    <input name="percentInstitutions" type="text" {...register('percentInstitutions')} className={`form-control ${errors.percentInstitutions ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.percentInstitutions?.message}</div>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-1">
                    <label>Country Code</label>
                    <input name="countryCode" type="text" {...register('countryCode')} className={`form-control ${errors.countryCode ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.countryCode?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Inception Date</label>
                    <input name="inceptionDate" type="text" {...register('inceptionDate')} className={`form-control ${errors.inceptionDate ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.inceptionDate?.message}</div>
                </div>
                <div className="form-group col-2">
                    <label>Expiration Date</label>
                    <input name="expirationDate" type="text" {...register('expirationDate')} className={`form-control ${errors.expirationDate ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.expirationDate?.message}</div>
                </div>
                <div className="form-group col-1">
                    <label>Active</label>
                    <input name="active" type="checkbox" {...register('active')} className={`form-control ${errors.active ? 'is-invalid' : ''}`} />
                    <div className="invalid-feedback">{errors.active?.message}</div>
                </div>
            </div>
            <div className="form-group">
                <button type="submit" disabled={formState.isSubmitting} className="btn btn-primary">
                    {formState.isSubmitting && <span className="spinner-border spinner-border-sm mr-1"></span>}
                    Save
                </button>
                <Link to={isAddMode ? '.' : '..'} className="btn btn-link">Cancel</Link>
            </div>
            <input name="stockGuid" type="hidden" {...register('stockGuid')} />
            <input name="createdBy" type="hidden" {...register('createdBy')} />
            <input name="createdDate" type="hidden" {...register('createdDate')} />
        </form>
    );
}

export default AddEditStock;