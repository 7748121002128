import React, { Component } from 'react'

import { Redirect } from "react-router-dom";

import AuthenticationService from '../../services/security/AuthenticationService';

import "../../styles/Login.css";

class LoginComponent extends Component {

    constructor(props) {
        super(props)

        this.state = {
            userName: '',
            password: '',
            hasLoginFailed: false,
            loginSuccessful: false,
            handleUserLoggedIn: props.handleUserLoggedIn
        }

        this.handleChange = this.handleChange.bind(this)
        this.loginClicked = this.loginClicked.bind(this)
    }

    handleChange(event) {
        this.setState(
            {
                [event.target.name]
                    : event.target.value
            }
        )
    }

    loginClicked = event => {

        // AuthenticationService
        //     .executeBasicAuthenticationService(this.state.userName, this.state.password)
        //     .then(() => {
        //         AuthenticationService.registerSuccessfulLogin(this.state.userName, this.state.password)
        //         this.props.history.push(`/courses`)
        //     }).catch(() => {
        //         this.setState({ loginSuccessful: false })
        //         this.setState({ hasLoginFailed: true })
        //     })

        AuthenticationService
            .executeJwtAuthenticationService(this.state.userName, this.state.password)
            .then((response) => {
                AuthenticationService.registerSuccessfulLoginForJwt(this.state.userName, response.data.jwt)
                this.state.handleUserLoggedIn(true);
                this.setState({ loginSuccessful: true })
                //this.props.history.push(`/`)
            }).catch(() => {
                this.setState({ loginSuccessful: false })
                this.setState({ hasLoginFailed: true })
            })
        
        event.preventDefault();
    }

    render() {
        if (this.state.loginSuccessful) {
            return <Redirect to="/" />;
        }
        return (
            
            <div className="container">
                <div className="login-box">
                    <form onSubmit={this.loginClicked} className="form-signin">
                        <h2 className="h2 mb-2 font-weight-normal">LMental Finance</h2>
                        <div className="row">
                            <div className="col">
                                <hr />
                                <label>
                                    <input
                                    type="text"
                                    name="userName"
                                    value={this.state.userName}
                                    onChange={this.handleChange}
                                    placeholder="Enter Username"
                                    className="form-control"
                                    />
                                </label>
                                <label>
                                    <input
                                    type="password"
                                    name="password"
                                    value={this.state.password}
                                    onChange={this.handleChange}
                                    placeholder="Enter Password"
                                    className="form-control"
                                />
                                </label>
                                <hr />
                                <div className="submitGroup">
                                    <input type="submit" value="Login" />
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}

export default LoginComponent