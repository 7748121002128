
import axios from "axios";

const FINAPI_URL = process.env.REACT_APP_FINAPI_URL;
const baseUrl = `${FINAPI_URL}/StockList`;

const getAll = async (params) => {

  const response = await axios.get(
      `${baseUrl}${params}`
    )
    .catch( handleRequestError );
  
  return response;
}

const getById = async (listName, dateStr) => {
  //console.log(`get stock list by id [${listName}, ${dateStr}], baseUrl = [${baseUrl}]`)

  const response = await axios.get(
    `${baseUrl}/${listName}/${dateStr}`
  )
  .catch( handleRequestError );
  
  return response.data;
}

const create = async (data) => {
  console.log("create stock list, data =", data, "baseUrl =", baseUrl)

  const response = await axios.post(
    baseUrl, data
  )
  .catch( handleRequestError );

  return response.data;
}

const update = async (listName, dateStr, data) => {
  console.log(`update stock list, id [${listName}, ${dateStr}], baseUrl = [${baseUrl}]`)

  const response = await axios.put(
    `${baseUrl}/${listName}/${dateStr}`, data
  )
  .catch( handleRequestError );

  return response.data;
}

// prefixed with underscored because delete is a reserved word in javascript
const _delete = async (listName, dateStr) => {
  console.log(`delete stock list, id [${listName}, ${dateStr}], baseUrl = [${baseUrl}]`)

  const response = await axios.delete(
    `${baseUrl}/${listName}/${dateStr}`
  )
  .catch( handleRequestError );

  return response;
}

const deleteAll = async (batchData) => {
  console.log("delete stock lists, batchData =", batchData, "baseUrl =", baseUrl)

  const response = await axios.post(
    `${baseUrl}/deleteInBatch`, batchData
  )
  .catch( handleRequestError );

  return response.data;
}

const handleRequestError = (error) => {

  const errorMsg =
    (error.response && ("Error making server call: server responded with: " +
      `${error.response.data.path} ${error.response.data.status} ${error.response.data.error}`)
    ) ||
    (error.request && `Error making server call: no response was received, request = ${error.request}`) ||
    `Error making server call: problem setting up request, message = ${error.message}`;

  return Promise.reject(errorMsg);
}

export const stockListService = {
    getAll,
    getById,
    create,
    update,
    delete: _delete,
    deleteAll
};